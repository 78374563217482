.container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 100%;
  max-width: 400px;
}

.label {
  /* font-weight: bold; */
  margin-bottom: 0.2rem;
}

.base {
  border: 2px solid #c5c5c5;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px;
  border-radius: 6px;
  font-size: 16px;
}

.input:invalid {
  border: 2px solid red;
}
