body {
  position: absolute;
  min-height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  color: rgba(29, 29, 29, 0.7);
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-weight: 400;
  color: black;
}

b {
  font-weight: 400;
}

.root {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .root {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .root {
    width: 720px;
  }
}
@media only screen and (min-width: 992px) {
  .root {
    width: 720px;
  }
}
@media only screen and (min-width: 1600px) {
  .root {
    width: 1100px;
  }
}
