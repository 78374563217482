.button {
  all: unset;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  -webkit-text-fill-color: white;
  background-color: #2556b1;
  border: 2px solid #2556b1;
  margin: 10px;
}

.button:not(:disabled):hover {
  filter: brightness(0.86);
}

.button:focus-visible {
  outline: orange 5px auto;
}

.button:disabled {
  background-color: #9c9c9c;
  border: 2px solid #9c9c9c;
  cursor: default;
}

.outline {
  all: unset;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: 2px solid #2556b1;
  color: #2556b1;
  -webkit-text-fill-color: #2556b1;
  background-color: white;
  margin: 10px;
}

.outline:hover {
  filter: brightness(0.86);
}

.outline:focus-visible {
  outline: orange 5px auto;
}
