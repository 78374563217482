.button {
  margin-bottom: 6%;
}

.heroImage {
  width: 100%;
  border-radius: 10px;
}

.title {
  font-size: 3.6em;
  font-weight: 300;
  margin: 9% 0 20px 0;
  text-align: center;
}

.subtitle {
  font-size: 1.6em;
  font-weight: 300;
  text-align: center;
}

.playerArea {
  width: 80%;
  min-height: 150px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
