.page {
  margin: auto;
}

.container {
  text-align: center;
  background-color: white;
  padding: 30px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80vh;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 25px 4px rgb(0 0 0 / 0.1), 0 0 10px 4px rgb(0 0 0 / 0.1);
}

.headerImage {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.heading {
  font-size: 1.6em;
}
