.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 10px 0;
}

.label {
  color: black;
  font-weight: 400;
  font-size: 1.1em;
  flex: 1;
  display: flex;
  align-items: center;
}

.button {
  all: unset;
  background-color: rgb(240, 240, 240);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 20px;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  margin-right: 10px;
}

.button:hover {
  filter: brightness(0.9);
}

.button:focus-visible {
  outline: orange 5px auto;
}
