.audio-react-recorder__canvas {
  width: 100%;
  height: auto;
}

.rhap_container {
  box-shadow: none;
}

.rhap_main-controls-button,
.rhap_repeat-button,
.rhap_volume-button {
  color: #2556b1;
}

.rhap_volume-indicator,
.rhap_progress-indicator,
.rhap_progress-filled {
  background: rgb(37, 86, 177);
}

.rhap_volume-bar,
.rhap_progress-bar,
.rhap_download-progress,
.rhap_progress-bar-show-download {
  background: #cacbff;
}
