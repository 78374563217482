.avatar {
  height: 40px;
  width: 40px;
  margin: 0 12px 0 0;
  /* border: 1px solid black; */
  background-color: #c7dcee;
  fill: #4848b9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  margin: 14px 0;
  font-size: 1.1rem;
  min-width: 0;
  flex: 1;
}

.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
