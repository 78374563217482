.container {
  align-self: stretch;
  min-height: 100vh;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col1 {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: left;
  flex-direction: column;
}

.col2 {
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.col3 {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.topBarItem {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.topBarItem:first-child > span {
  margin-right: auto;
}

.topBarItem:last-child > span {
  margin-left: auto;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #e50000;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
