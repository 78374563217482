.base {
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input {
  margin-right: 10px;
}

.error {
  background-color: #ffbdbd;
  color: #d10000;
}
