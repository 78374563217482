.container {
  width: 100%;
  background-color: #c5e9ff8c;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
}

.textContainer {
  min-width: 0;
  margin: 16px 12px;
  flex-basis: 200px;
  flex-grow: 9999;
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
}

.text {
  display: inline-block;
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  font-weight: 400;
  text-decoration: none;
}

.text:hover {
  text-decoration: underline;
}
