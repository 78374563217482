.container {
  align-self: stretch;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px 0;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.heroImage {
  width: 100%;
  border-radius: 10px;
}

.instructions {
  font-size: 1.2rem;
  margin: 30px 0;
}

li {
  padding: 8px 0;
}

.job {
  border: 3px solid #777777;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 30px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobTitle {
  margin: 0 0 20px 0;
}

.downloadArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadText {
  width: 100%;
  background-color: #c5e9ff8c;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px;
}
