.titleImage {
  width: 100%;
  display: block;
  margin: 40px 0;
}

.intro {
  font-size: 1.2rem;
  color: rgba(29, 29, 29, 0.7);
  padding: 2%;
}

.header {
  text-align: center;
  font-weight: 400;
}

.flowerBannerTop {
  width: 100%;
  display: block;
  height: 30px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 50px;
}

.flowerBannerBottom {
  width: 100%;
  display: block;
  height: 30px;
  object-fit: cover;
  object-position: 0 100%;
  border-radius: 10px;
  margin-bottom: 50px;
}

dl.steps dt {
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 8px;
}

.time {
  font-size: min(max(13vw, 2em), 4em);
}

.timeRecording {
  font-size: min(max(13vw, 2em), 4em);
  color: rgb(200, 0, 0);
  font-weight: 400;
}

.mainArea {
  width: 80%;
  min-height: 150px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirm {
  font-size: 1.2rem;
  padding: 2%;
  text-align: center;
}

.confirmation {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 2%;
  color: #2556b1;
  text-align: center;
}

.buttonArea {
  margin-bottom: 50px;
}

.control {
  all: unset;
  /* box-sizing: border-box; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 2px solid black; */
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control:hover {
  filter: brightness(0.86);
}

.control:focus-visible {
  outline: orange 5px auto;
}

.start {
  background-color: white;
  border: 3px solid red;
  color: white;
  fill: red;
}

.stop {
  background-color: red;
  border: 3px solid red;
  color: red;
  fill: white;
}

.stop::before {
  content: "";
  position: absolute;
  display: block;
  width: 115px;
  height: 115px;
  box-sizing: border-box;
  background-color: red;
  border-radius: 60px;
  z-index: -1;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1)
    infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

.restart {
  background-color: white;
  border: 3px solid black;
  color: black;
  fill: black;
}
